import React from 'react'
import { ChevronRight, ChevronDown } from 'react-bootstrap-icons'
import { useFormTreeEl } from '../hooks/useFormTreeEl'

interface iSectionHeaderToggle {
  open: boolean
  setOpen: (open: boolean) => void
}

const MAX_TOGGLE_LEVEL = 2

const SectionHeaderToggle = ({ open, setOpen }: iSectionHeaderToggle) => {
  const formTreeEl = useFormTreeEl()
  const showToggle = formTreeEl?.level !== undefined && formTreeEl?.level < MAX_TOGGLE_LEVEL

  if (!showToggle) {
    return null
  }

  return (
    <button type='button' className='form-title-toggle' onClick={() => setOpen(!open)}>
      {open ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
    </button>
  )
}

export default SectionHeaderToggle
