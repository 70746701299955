export const ENV = {
  EFORMS_API_URL: process.env.REACT_APP_EFORMS_API_URL!,
  FE_URL: process.env.REACT_APP_FE_URL!,
  EFORMS_ADMIN_TOKEN: process.env.REACT_APP_EFORMS_ADMIN_TOKEN!,
  EFORMS_DEV_ORG_SLUG: process.env.REACT_APP_EFORMS_DEV_ORG_SLUG!,
  VERSION: process.env.REACT_APP_VERSION,
  REF_ENV: process.env.REACT_APP_REF_ENV === 'true',
  DEBUG_MODE: process.env.REACT_APP_DEBUG_MODE === 'true' || window.location.hash.includes('debug'),
  UPLOAD_MAX_FILES_SIZE: 19 * 1024 * 1024,
  CACHE_EXPIRATION: parseFloat(process.env.REACT_APP_CACHE_EXPIRATION_HOURS!) * 60 * 60 * 1000, // hodina v milisekundách
  ENUM_ID_REPAIR_TYPE: 'cd3a2e68-9f6c-4ae1-be83-20c1f5e890db',
  EFORM_ATTACH_TYPE: 'form',
  EFORM_ZADOST_SCHEMA_ID: '823abce8-88a5-4fec-87eb-bd410502c36e',
  BASE_LOGIN: process.env.REACT_APP_BASE_LOGIN === 'true',
  MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE === 'true' && !window.location.hash.includes('fullmode'),
  URL_TED_FORM: 'https://ted.europa.eu/cs/notice/-/detail/',
  FORM_TREE_SIZE_REPEATS_BIG: 300,
  FORM_TREE_SIZE_REPEATS_EXTREME: 600,
}
